<script setup>
    import { defineProps, computed } from 'vue'

    const props = defineProps({
        icon: {
            type: String
        },
        type: {
            type: String,
            default: 'primary'
        },
        loaded: {
            type: Boolean,
            default: false
        },
        verified: {
            type: Boolean,
            default: false
        },
        disabled: {
          type: Boolean,
          default: false
        },
        visible: {
          type: Boolean,
          default: true
        },
        block: {
          type: Boolean,
          default: false
        },
        large: {
          type: Boolean,
          default: false
        },
        size: {
          type: String,
          default: null,
        },
        corner: {
          type: String,
          default: null,
        },
        loading: {
          type: Boolean,
          default: false
        },
        loadFunction: {
            type: Function,
            default: null
        },
        fluid: {
          type: Boolean,
          default: false
        },
        tooltip: {
          type: String,
          default: null
        },
        color: {
            type: String,
            default: null
        },
        minWidth: {
            type: String,
            default: null
        },
        dark: {
            type: Boolean,
            default: false,
        }
    })
    const emit = defineEmits(['click', 'loaded'])

    const loading = ref(false)
    const verifying = ref(false)
    const loadingColor = computed(() => {
        if (props.type == 'primary') {
            return 'white'
        }
        return 'primary'
    })

    const click = () => {
        // Check if we need to verify first
        if (props.verified) {
            verifying.value = true
            return
        }
        // Else just click
        executeClick()
    }

    const executeClick = async () => {
        emit('click')

        // Make async load with loading spinner
        if (props.loaded && props.loadFunction != null) {
            loading.value = true
            var rtn = await props.loadFunction()
            loading.value = false
            emit('loaded', rtn)
        }
    }

    // When the dialog was verified
    const verify = async () => {
        verifying.value = false
        executeClick()
    }

    const wrapperClasses = computed(() => {
      let classes = "d-inline-block"
      // We need to set the full widht when in block mode
      if (props.block) classes += " w-100"
      // If we have min-width we need to extend the parent to support width sizing
      if (props.minWidth) classes += " text-center w-100"
      return classes
    })

    const buttonSize = computed(() => {
      if (props.large) return 'large'
      if (props.size) return props.size
      return undefined
    })

    // Computed
    const style = computed(() => {
        let stly = {}
        if (props.color) {
            stly.backgroundColor = `${props.color} !important`
        }
        if(props.dark) {
            stly.color = `black !important`
        }
        return stly
    })

    const isDisabled = computed(() => {
      return props.disabled || isLoading.value
    })

    const isLoading = computed(() => {
      return props.loading || loading.value
    })

    const classes = computed(() => {
      let classes = 'ts-button'
      // Add type class
      classes += ' ts-button-' + props.type
      // Add padding if needed
      if (!props.fluid) {
        classes += ' px-3'
      } else {
        classes += ' pa-0 ts-button-no-min-width'
      }
      // Add custom size if needed
      if (props.corner) {
        classes += ` rounded-${props.corner}`
      } else {
        classes += ` rounded-lg`
      }
      return classes
    })

    const hasTooltip = computed(() => {
      return !!props.tooltip
    })

</script>


<template>
    <div :class="wrapperClasses">
        <v-dialog
            v-model="verifying"
            width="auto"
        >
            <v-card width="600" class="rounded-xl pa-2">
                <v-card-title class="font-weight-bold">Bestätigung</v-card-title>
                <v-card-text>
                    <slot name="verify"></slot>
                </v-card-text>
                <v-card-actions>
                    <v-btn elevated class="rounded-lg ts-button-secondary text-none" @click="verifying = false">Abbrechen</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn elevated class="rounded-lg ts-button-primary text-none" @click="verify">Bestätigen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-btn
            v-if="visible"
            @click="click"
            :prepend-icon="props.icon"
            elevated
            :block="block"
            :size="buttonSize"
            :disabled="isDisabled"
            :class="classes"
            class="text-none font-weight-bold"
            :style="style"
            :min-width="minWidth"
        >
            <v-tooltip v-if="hasTooltip" :text="tooltip" activator="parent"></v-tooltip>
            <slot v-if="!isLoading"></slot>
            <v-progress-circular
                v-if="isLoading"
                indeterminate
                :color="loadingColor"
            ></v-progress-circular>
        </v-btn>
    </div>
</template>

<!-- Style overrides, because Amit fucked up the frontend and also the styles... -->
<style scoped>
    .ts-button-no-min-width {
      min-width: 0 !important;
    }
    .ts-button-height-workaround {
      height: 30px;
    }
    .ts-button-primary {
        background-color: #009ee3 !important;
        color: white !important;
    }
    .ts-button-secondary {
        background-color: #ECECEE !important;
        color: #544F5A !important;
    }
    .ts-button-secondary-light {
       background-color: #DFE0EB !important;
       color: #FFFFFF !important;
    }
    .ts-button-danger {
      background-color: red !important;
      color: white !important;
    }
    .ts-button-success {
      background-color: #27AE60 !important;
      color: white !important;
    }
    .ts-button-plain {
        background-color: transparent !important;
        color: #544F5A !important;
    }
    .ts-button-plain.v-btn--variant-elevated {
        box-shadow: none;
    }
</style>